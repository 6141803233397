export const environment = {
    production: true,
    apiUrl: 'https://stag.digibord.thiememeulenhoff.nl',
    sentryDsn:
        'https://89f74c0352c44a7d995f04fd8faba288@o37556.ingest.sentry.io/5728370',
    environment: 'stag',
    googleAnalyticsCode: 'G-GPKDYW6S0T',
    thasToken:
        '6aa2e2166b63ff3a4ca9772974ac0c50b4a41d684935f5ec57d19fc551a32809',
};
